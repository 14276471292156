var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Login")])],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.doLogin.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-account","label":"Username","type":"text","error-messages":errors},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"password","prepend-icon":"mdi-lock","label":"Password","type":"password","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Google Auth Code","rules":"required|min:6|max:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-google","label":"Google Auth Code","type":"text","error-messages":errors},model:{value:(_vm.googleAuth),callback:function ($$v) {_vm.googleAuth=$$v},expression:"googleAuth"}})]}}],null,true)}),_c('br'),_c('v-progress-linear',{attrs:{"active":_vm.submitted,"indeterminate":_vm.submitted,"absolute":"","bottom":"","color":"deep-purple accent-4"}}),_c('p',[(!_vm.submitted)?_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid}},[_c('v-icon',[_vm._v("exit_to_app")]),_vm._v(" "+_vm._s(_vm.LOGIN_TEXT))],1):_vm._e()],1),(_vm.alert.message)?_c('v-alert',{attrs:{"dense":"","type":_vm.alert.type,"timeout":"5000"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]):_vm._e()],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }