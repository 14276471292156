<template>
  <div>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                    <v-toolbar-title>Login</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <validation-observer
                            ref="observer"
                            v-slot="{ invalid }"
                        >
                            <v-form     
                            v-model="valid"
                            lazy-validation @submit.prevent="doLogin">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Username"
                                rules="required|min:4"
                            >
                                <v-text-field
                                    prepend-icon="mdi-account"
                                    label="Username"
                                    type="text"
                                    v-model="username"
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                            <validation-provider
                                v-slot="{ errors }"
                                name="Password"
                                rules="required|min:4"
                            >
                                <v-text-field
                                    id="password"
                                    prepend-icon="mdi-lock"
                                    label="Password"
                                    type="password"
                                    v-model="password"
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                            <validation-provider
                                v-slot="{ errors }"
                                name="Google Auth Code"
                                rules="required|min:6|max:6"
                            >
                                <v-text-field
                                    prepend-icon="mdi-google"
                                    label="Google Auth Code"
                                    type="text"
                                    v-model="googleAuth"
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                                <br />
                                <v-progress-linear
                                    :active="submitted"
                                    :indeterminate="submitted"
                                    absolute
                                    bottom
                                    color="deep-purple accent-4"
                                ></v-progress-linear>
                                <p><v-btn color="primary" type="submit" :disabled="invalid" v-if="!submitted"><v-icon>exit_to_app</v-icon> {{ LOGIN_TEXT }}</v-btn></p>
                                <v-alert
                                    dense
                                    :type="alert.type"
                                    timeout="5000"
                                v-if="alert.message">
                                    {{ alert.message }}
                                </v-alert>
                            </v-form>
                        </validation-observer>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
  </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { required, max, min } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
    import { JSEncrypt } from 'jsencrypt'
  setInteractionMode('eager')

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('min', {
    ...min,
    message: '{_field_}  must be {length} or more',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

export default {
  name: 'team',
  methods:{
    ...mapActions('auth', ['login', 'logout']),
    doLogin() {
        this.submitted = true;
        const { username, password, googleAuth } = this;
        if (username && password && googleAuth) {
            fetch(`${this.$API}/getPublicKey`).then((response)=>{
                return response.text();
            }).then((publicKey)=>{
                if(publicKey){
                    let encryptor = new JSEncrypt();
                    encryptor.setPublicKey(publicKey);
                    let EncryptedPassword = encryptor.encrypt(password);
                    return EncryptedPassword;
                }else{
                    this.error = "Can't get Public Key. Please try again !!!";
                    return false;
                }
            }).then((password)=>{
                if(password){
                    this.login({username, password, googleAuth });
                }
            }).finally(()=>{
                this.submitted = false;
            });
        }
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    
  },
  watch:{
  },
  computed: {
      ...mapState('auth', ['status']),
      ...mapState({
          alert: state => state.alert
      })
  },
  data : () => ({
    submitted:false,
    valid: true,
    username: '',
    password: '',
    googleAuth: '',
    invalid: true,
    error: false,
    LOGIN_TEXT: "Login"
  }),
}
</script>
